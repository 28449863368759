import { useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";

import ConfirmOtp from "../../../components/common/ConfirmOtp";
import UserDetails from "../../../components/InitiateTransfer/UserDetails";
import { submitMakvidTransfer } from "../../../api/admin/dashboard";
import { resendOtp, verifyOtp, sendOtp } from "../../../api/common/auth";
import { useCountdown } from "../../../hooks/useCoundown";
import { addSeconds, hashString } from "../../../utils/helpers/functions";

const mainContainerStyle = {
	display: "flex",
	justifyContent: "center",
};

const mainStyle = {
	width: "33rem",
	mb: "2rem",
	p: "2rem",
	// border: "1px solid #E6E6E6",
};
const initialValues = {
	usernames: [
		{ username: "", fullname: "", amount: "", accountType: "", comment: "" },
	],
};

const validationSchema = yup.object().shape({
	usernames: yup.array().of(
		yup.object().shape({
			username: yup
				.string()
				.min(3, "Username must be atleast 3 characters long")
				.required("Username is required"),
			fullname: yup
				.string()
				.min(3, "Full name must be at least 3 characters long")
				.required("Full name is required"),
			amount: yup
				.number()
				.positive("Amount must be positive")
				.required("Amount is required"),
			accountType: yup.string().required("Account type is required"),
			comment: yup
				.string()
				.min(3, "Comment must be atleast 3 characters long")
				.max(200, "Comment must be at most 200 characters long")
				.required("Comment is required"),
		})
	),
});

const InitiateTransfer = () => {
	const [otp, setOtp] = useState("");
	const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
	const [targetDate, setTargetDate] = useState(null);
	const [keywords, setKeywords] = useState([""]);
	const [isVerifyLoading, setIsVerifyLoading] = useState(false);

	const { region, bizFrom, user } = useSelector((state) => state.auth);
	const [days, hours, minutes, seconds] = useCountdown(targetDate);

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: () => {
			handleRequestOtp();
		},
	});

	const handleSubmit = async () => {
		try {
			const formikValues = formik.values;
			const payload = {
				username: user?.username,
				region: region?.region,
				otp: hashString(otp),
				recipients: formikValues.usernames?.map((item) => ({
					recipient_username: item?.username,
					recipient_fullName: item?.fullname,
					amount: Number(item?.amount),
					accountType: item?.accountType,
					comment: item?.comment,
				})),
			};
			const result = await submitMakvidTransfer(payload);
			setOtp("");
			formik.setSubmitting(false);
			formik.resetForm();
			toast.success(result.message);
		} catch (error) {
			setOtp("");
			formik.setSubmitting(false);
			if (error.response) {
				toast.error(error.response.data?.message);
			} else if (error.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", error.message);
			}
		}
	};

	const addFieldSet = () => {
		formik.setFieldValue("usernames", [
			...formik.values.usernames,
			{
				username: "",
				fullname: "",
				amount: "",
				comment: "",
			},
		]);
		setKeywords([...keywords, ""]);
	};

	const removeFieldSet = (index) => {
		const currentUsernames = [...formik.values.usernames];
		currentUsernames.splice(index, 1);
		formik.setFieldValue("usernames", currentUsernames);
		const currentKeywords = [...keywords];
		currentKeywords.splice(index, 1);
		setKeywords(currentKeywords);
	};

	const handleRequestOtp = async () => {
		try {
			const payload = {
				username: user.username,
				fullName: user.fullName,
				emailAddress: user.emailAddress,
				phoneNumber: user.phoneNumber,
				region: region?.region,
				bizFrom,
			};
			const result = await sendOtp(payload);
			setTargetDate(addSeconds(1800));
			setIsOtpModalOpen(true);
			toast.success(result?.message);
		} catch (error) {
			setOtp("");
			formik.setSubmitting(false);
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", error.message);
			}
		}
	};

	const handleOtpResend = async () => {
		setTargetDate(addSeconds(1800));
		try {
			const payload = {
				username: user.username,
				fullName: user.fullName,
				emailAddress: user.emailAddress,
				phoneNumber: user.phoneNumber,
				region: region?.region,
				bizFrom,
			};
			await resendOtp(payload);
			toast.success("OTP sent to registered email adddress");
		} catch (error) {
			setOtp("");
			formik.setSubmitting(false);
			if (error.response) {
				toast.error(error.response.data?.message);
			} else if (error.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", error.message);
			}
		}
	};

	const handleOtpVerify = async () => {
		if (!otp) {
			toast.error("Please enter OTP");
			return;
		}
		try {
			setIsVerifyLoading(true);
			const payload = {
				otp: hashString(otp),
				username: user.username,
				emailAddress: user.emailAddress,
				region: region?.region,
				bizFrom,
			};
			const result = await verifyOtp(payload);
			setIsVerifyLoading(false);
			toast.success(result.message);
			setIsOtpModalOpen(false);
			await handleSubmit();
		} catch (error) {
			setOtp("");
			setIsVerifyLoading(false);
			formik.setSubmitting(false);
			if (error.response) {
				toast.error(error.response.data?.message);
			} else if (error.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", error.message);
			}
		}
	};

	return (
		<Box
			sx={{
				p: {
					md: "2rem",
					xs: "1rem",
				},
			}}
		>
			{/* header */}
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					flexDirection: { sm: "row", xs: "column" },
					mb: "2rem",
				}}
			>
				<Typography
					variant="h6"
					sx={{ fontWeight: 700, textTransform: "capitalize", mr: "auto" }}
					gutterBottom
				>
					Initiate Transfer
				</Typography>
			</Box>

			{/* body */}
			<Box sx={mainContainerStyle}>
				<Box sx={mainStyle}>
					<Typography
						variant="body1"
						sx={{ textAlign: "center", fontWeight: 500, mb: "2rem" }}
						color="primary"
					>
						Enter account Details to initiate transfer
					</Typography>
					<Box
						component="form"
						noValidate
						autoComplete="off"
						onSubmit={formik.handleSubmit}
					>
						{formik.values.usernames.map((_, index) => (
							<Box key={index}>
								<UserDetails
									formik={formik}
									index={index}
									removeFieldSet={removeFieldSet}
									keyword={keywords[index]}
									setKeyword={(value) => {
										setKeywords([
											...keywords.slice(0, index),
											value,
											...keywords.slice(index + 1),
										]);
									}}
								/>
							</Box>
						))}

						<AddButtonContainer>
							<IconButton
								onClick={addFieldSet}
								sx={{ border: "1px solid #9d9d9d" }}
							>
								<AddIcon sx={{ fontSize: "2rem" }} />
							</IconButton>
						</AddButtonContainer>

						<Button
							type="submit"
							variant="contained"
							color="primary"
							fullWidth
							sx={{ padding: ".85rem" }}
							disabled={!formik.dirty || formik.isSubmitting}
						>
							{formik.isSubmitting ? (
								<CircularProgress size="1.5rem" />
							) : (
								"submit"
							)}
						</Button>
					</Box>
				</Box>
			</Box>

			<ConfirmOtp
				open={isOtpModalOpen}
				handleClose={() => {
					setOtp("");
					setIsOtpModalOpen(false);
				}}
				otp={otp}
				handleChange={(value) => setOtp(value)}
				handleOtpRequest={handleOtpResend}
				handleVerifyOtp={handleOtpVerify}
				time={{ days, hours, minutes, seconds }}
				loading={isVerifyLoading}
			/>
		</Box>
	);
};

export default InitiateTransfer;

const AddButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 1rem;
`;
