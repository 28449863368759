import { useState } from "react";
import { useQueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import Table from "./CompleteMakvidTransferTable";
import ViewMakvidTransferModal from "../common/ViewMakvidTransferModal";
import ConfirmOtp from "../common/ConfirmOtp";
import {
	approveMakvidTransfer,
	rejectMakvidTransfer,
} from "../../api/admin/dashboard";
import { resendOtp, verifyOtp, sendOtp } from "../../api/common/auth";
import { useCountdown } from "../../hooks/useCoundown";
import { addSeconds, hashString } from "../../utils/helpers/functions";

const actionsContainerStyle = {
	display: "flex",
	alignItems: "center",
	gap: ".5rem",
	justifyContent: {
		xs: "flex-start",
		md: "flex-end",
	},
	mb: ".5rem",
};

const CompleteTransfertab = ({ data, loading }) => {
	const [selectedItem, setSelectedItem] = useState(null);
	const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);
	const [otp, setOtp] = useState("");
	const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
	const [targetDate, setTargetDate] = useState(null);
	const [isVerifyLoading, setIsVerifyLoading] = useState(false);
	const [currentMutationAction, setCurrentMutationAction] = useState("");
	const [selectedItems, setSelectedItems] = useState([]);
	const [otpRequestLoading, setOtpRequestLoading] = useState(false);

	const { region, user, bizFrom } = useSelector((state) => state.auth);
	const [days, hours, minutes, seconds] = useCountdown(targetDate);

	const queryClient = useQueryClient();

	const { mutate: approveMakvidTransferMutation, isLoading: isApproveLoading } =
		useMutation("approve-transfer-request", approveMakvidTransfer, {
			onSuccess: (dataSuccess) => {
				toast.success(dataSuccess?.message);
				setOtp("");
				setSelectedItems([]);
			},
			onError: (errors) => {
				setOtp("");
				setSelectedItems([]);
				if (errors.response) {
					toast.error(errors.response.data.message);
				} else if (errors.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", errors.message);
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries("get-makvid-transfer");
			},
		});

	const { mutate: rejectMakvidTransferMutation, isLoading: isRejectLoading } =
		useMutation("reject-transfer-request", rejectMakvidTransfer, {
			onSuccess: (dataSuccess) => {
				toast.success(dataSuccess?.message);
				setOtp("");
				setSelectedItems([]);
			},
			onError: (errors) => {
				setOtp("");
				setSelectedItems([]);
				if (errors.response) {
					toast.error(errors.response.data.message);
				} else if (errors.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", errors.message);
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries("get-makvid-transfer");
			},
		});

	const handleApprove = () => {
		const selected = data?.filter((item) => selectedItems?.includes(item.id));
		const payload = {
			otp: hashString(otp),
			username: user?.username,
			recipients: selected?.map((item) => ({
				id: item.id,
				recipient_username: item?.recipient_username,
				amount: Number(item?.amount),
				accountType: item?.accountType,
			})),
		};

		approveMakvidTransferMutation(payload);
	};
	const handleReject = () => {
		const selected = data?.filter((item) => selectedItems?.includes(item.id));
		const payload = {
			otp: hashString(otp),
			username: user?.username,
			recipients: selected?.map((item) => ({
				id: item.id,
				recipient_username: item?.recipient_username,
				amount: Number(item?.amount),
				accountType: item?.accountType,
			})),
		};

		rejectMakvidTransferMutation(payload);
	};

	const handleRequestOtp = async () => {
		try {
			setOtpRequestLoading(true);
			const payload = {
				username: user.username,
				fullName: user.fullName,
				emailAddress: user.emailAddress,
				phoneNumber: user.phoneNumber,
				region: region?.region,
				bizFrom,
			};
			const result = await sendOtp(payload);
			setTargetDate(addSeconds(1800));
			setIsOtpModalOpen(true);
			toast.success(result?.message);
			setOtpRequestLoading(false);
		} catch (error) {
			setOtp("");
			setOtpRequestLoading(false);
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", error.message);
			}
		}
	};

	const handleOtpResend = async () => {
		setTargetDate(addSeconds(1800));
		try {
			setOtpRequestLoading(true);
			const payload = {
				username: user.username,
				fullName: user.fullName,
				emailAddress: user.emailAddress,
				phoneNumber: user.phoneNumber,
				region: region?.region,
				bizFrom,
			};
			await resendOtp(payload);
			toast.success("OTP sent to registered email adddress");
			setOtpRequestLoading(false);
		} catch (error) {
			setOtp("");
			setOtpRequestLoading(false);
			if (error.response) {
				toast.error(error.response.data?.message);
			} else if (error.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", error.message);
			}
		}
	};

	const handleOtpVerify = async () => {
		if (!otp) {
			toast.error("Please enter OTP");
			return;
		}
		try {
			setIsVerifyLoading(true);
			const payload = {
				otp: hashString(otp),
				username: user.username,
				emailAddress: user.emailAddress,
				region: region?.region,
				bizFrom,
			};
			const result = await verifyOtp(payload);
			setIsVerifyLoading(false);
			toast.success(result.message);
			setIsOtpModalOpen(false);
			if (currentMutationAction === "approve") {
				handleApprove();
				return;
			}
			handleReject();
		} catch (error) {
			setOtp("");
			setIsVerifyLoading(false);
			if (error.response) {
				toast.error(error.response.data?.message);
			} else if (error.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", error.message);
			}
		}
	};

	const handleApproveClick = async () => {
		if (!selectedItems?.length) {
			toast.error("Please select atleast one request to approve ");
			return;
		}
		setCurrentMutationAction("approve");
		await handleRequestOtp();
	};

	const handleRejectClick = async () => {
		if (!selectedItems?.length) {
			toast.error("Please select atleast one request to reject ");
			return;
		}
		setCurrentMutationAction("reject");
		await handleRequestOtp();
	};

	return (
		<Box>
			<Box sx={actionsContainerStyle}>
				<Button
					variant="contained"
					diabled={otpRequestLoading || isApproveLoading || isRejectLoading}
					onClick={handleApproveClick}
				>
					{(otpRequestLoading && currentMutationAction === "approve") ||
					isApproveLoading ? (
						<CircularProgress size="1.5rem" sx={{ color: "#fff" }} />
					) : (
						"approve"
					)}
				</Button>
				<Button
					variant="contained"
					diabled={otpRequestLoading || isApproveLoading || isRejectLoading}
					onClick={handleRejectClick}
				>
					{(otpRequestLoading && currentMutationAction === "reject") ||
					isRejectLoading ? (
						<CircularProgress size="1.5rem" sx={{ color: "#fff" }} />
					) : (
						"reject"
					)}
				</Button>
			</Box>

			<Table
				data={data}
				loading={loading}
				setSelectedItem={setSelectedItem}
				handleView={() => setViewDetailsModalOpen(true)}
				selected={selectedItems}
				setSelected={setSelectedItems}
			/>

			<ViewMakvidTransferModal
				open={viewDetailsModalOpen}
				handleClose={() => {
					setViewDetailsModalOpen(false);
				}}
				title="View Transfer Details"
				data={selectedItem}
				setSelected={setSelectedItem}
			/>

			<ConfirmOtp
				open={isOtpModalOpen}
				handleClose={() => {
					setOtp("");
					setIsOtpModalOpen(false);
				}}
				otp={otp}
				handleChange={(value) => setOtp(value)}
				handleOtpRequest={handleOtpResend}
				handleVerifyOtp={handleOtpVerify}
				time={{ days, hours, minutes, seconds }}
				loading={isVerifyLoading}
			/>
		</Box>
	);
};

export default CompleteTransfertab;
