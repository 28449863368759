import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import {
	Box,
	TextField,
	Typography,
} from "../../../components/common/muiComponents";
import TextFieldComponent from "../../../components/common/TextFieldComponent";
import NumberTextField from "../../../components/common/NumberTextField";
import SubmitButton from "../../../components/common/SubmitButton";
import { postAMC } from "../../../api/admin/dashboard";

const fields = {
	username: "",
	amount: "",
	narration: "",
};

const validationSchema = yup.object({
	username: yup.string("Please enter username"),
	amount: yup.number().positive("Amount must be positive"),
	narration: yup.string(),
});

const AMC = () => {
	const { user, region } = useSelector((state) => state.auth);

	const handleSubmit = async (values, stopSubmitLoading, resetForm) => {
		try {
			const payload = {
				username: values.username,
				amount: values.amount,
				narration: values.narration,
				admin: user?.fullName,
				region: region?.region,
			};

			const result = await postAMC(payload);

			toast.success(result?.message);
			stopSubmitLoading();
			resetForm();
		} catch (err) {
			console.log({ err });
			stopSubmitLoading();
			if (err.response) {
				toast.error(err.response?.data?.message);
			} else if (err.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", err.message);
			}
		}
	};

	const formik = useFormik({
		initialValues: fields,
		validationSchema,
		onSubmit: (values) => {
			handleSubmit(
				values,
				() => formik.setSubmitting(false),
				() => formik.resetForm()
			);
		},
	});

	return (
		<Box
			sx={{
				minHeight: "100vh",
				backgroundColor: "#fff",
				position: "relative",
			}}
		>
			<Box
				sx={{
					padding: { md: "3rem", sm: "1rem", xs: 0 },
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
					}}
				>
					<Box
						sx={{
							width: "33rem",
							border: "1px solid #E6E6E6",
							borderRadius: "8px",
							pb: { md: "2rem", xs: "1rem" },
						}}
					>
						<Typography
							variant="h6"
							sx={{
								textAlign: "center",
								fontWeight: 700,
								textTransform: "capitalize",
								color: "#303030",
								p: { md: "2rem", xs: "1rem" },
							}}
						>
							AMC
						</Typography>
						<Box
							component="form"
							sx={{
								p: { md: "1rem 3rem", xs: "1rem" },
								// border: "1px solid red",
							}}
							noValidate
							autoComplete="off"
							onSubmit={formik.handleSubmit}
						>
							<Box sx={{ mb: "1rem" }}>
								<TextFieldComponent
									value={formik.values.title}
									onChange={formik.handleChange}
									required
									label="Username"
									name="username"
									error={
										formik.touched.username && Boolean(formik.errors.username)
									}
									helperText={formik.touched.username && formik.errors.username}
								/>
							</Box>
							<Box sx={{ mb: "1rem" }}>
								<NumberTextField
									value={formik.values.amount}
									onChange={formik.handleChange}
									required
									label="amount"
									name="amount"
									error={formik.touched.amount && Boolean(formik.errors.amount)}
									helperText={formik.touched.amount && formik.errors.amount}
								/>
							</Box>

							<Box sx={{ mb: "1rem" }}>
								<TextField
									id="narration"
									name="narration"
									label="Narration"
									multiline
									rows={4}
									value={formik.values.narration}
									onChange={formik.handleChange}
									fullWidth
								/>
							</Box>

							<SubmitButton
								text="submit"
								loading={formik.isSubmitting}
								disabled={!formik.dirty || formik.isSubmitting}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default AMC;
