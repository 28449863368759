import { useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
// import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import Login from "../../../components/Admin/Login";
import { login } from "../../../slices/authSlice";
import Navbar from "../../../components/Home/NavBar";
import FormFooter from "../../../components/common/FormFooter";
import { fetchRoles } from "../../../api/admin/dashboard";
import { hashString } from "../../../utils/helpers/functions";

const defaultLoginFields = {
	username: "",
	password: "",
};

const AdminLogin = () => {
	const { region, isAuthenticated, role, bizFrom } = useSelector(
		(state) => state.auth
	);
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();
	const { state } = location;

	const {
		data: userRolesData = [],
		// isLoading: userRolesLoading,
		isError: userRolesError,
		error: userRolesErrorMessage,
	} = useQuery(
		["get-admin-roles", region?.region, isAuthenticated],
		async () => fetchRoles(region?.region, bizFrom),
		{
			select: (data) => data?.data,
			staleTime: 4 * 60 * 1000,
			enabled: Boolean(region?.region) && isAuthenticated,
		}
	);

	useEffect(() => {
		if (userRolesError) {
			if (userRolesErrorMessage.response) {
				toast.error(userRolesErrorMessage.response.data?.message);
			} else if (userRolesErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", userRolesErrorMessage.message);
			}
		}
	}, [userRolesError, userRolesErrorMessage]);

	// check to see if user is already logged in
	useEffect(() => {
		if (
			isAuthenticated &&
			userRolesData
				?.map((item) => item?.roleID?.toLowerCase())
				?.includes(role?.toLowerCase())
		) {
			history.push(state ? state.from.pathname : "/dashboard");
		}
	}, [isAuthenticated]);

	const handleLoginSubmit = async (values, stopSubmitLoading, resetForm) => {
		try {
			const payload = {
				username: values.username.trim(),
				password: hashString(values.password.trim()),
				// password: values.password.trim(),
				region: region.region,
			};
			const result = await dispatch(login(payload)).unwrap();
			stopSubmitLoading();
			resetForm();
			toast.success(result.message);
			history.push({
				pathname: "/landing-page",
				state: {
					accessToken: result.data.accessToken,
				},
			});
		} catch (error) {
			stopSubmitLoading();
			toast.error(error);
		}
	};

	// (async () => {
	// 	const url = "https://adminapi.ourgwala.com/auth/login";
	// 	const { data } = await axios.post(url, {
	// 		username: "superadmin@ourgwala.com",
	// 		password: "testpassword1235",
	// 	});
	// 	console.log({ data });
	// })()
	// 	.then((res) => console.log({ res }))
	// 	.catch((err) => {
	// 		console.error(err);
	// 	});

	return (
		<div>
			<CssBaseline />
			<Box
				sx={{
					minHeight: "100vh",
					backgroundColor: "#fff",
					width: "100vw",
					position: "relative",
				}}
			>
				<Navbar toggleFlag />
				<Toolbar />
				<Box
					sx={{
						padding: { md: "2rem", sm: "1rem", xs: 0 },
					}}
				>
					{/* <FormHeader /> */}
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
						}}
					>
						<Box
							sx={{
								width: "33rem",
								border: "1px solid #E6E6E6",
								borderRadius: "4px",
							}}
						>
							{/* <LoginTabs
								activeTab={tab}
								onSignupClick={() => setTab(SIGNUPTABS.SIGNUP)}
								onLoginCLick={() => setTab(SIGNUPTABS.LOGIN)}
							/> */}
							{/* {tab === SIGNUPTABS.LOGIN && ( */}
							<Login
								loginFields={defaultLoginFields}
								handleSubmit={handleLoginSubmit}
							/>
							{/* // )} */}
							{/* {tab === SIGNUPTABS.SIGNUP && (
								<SignUp
									signUpFields={signUpFields}
									setSignUpFields={setSignUpFields}
									adminRoles={adminRoles}
									handleSubmit={handleSignUpSubmit}
								/>
							)} */}
						</Box>
					</Box>
				</Box>
			</Box>
			<FormFooter />
		</div>
	);
};

export default AdminLogin;
