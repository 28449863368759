/* eslint-disable object-shorthand */
/* eslint-disable prefer-const */
import * as XLSX from "xlsx";
import { format } from "date-fns";
import CryptoJS from "crypto-js";

import config from "../../config";

const { encryptionPassword, encryptionSecretKey } = config().secrets;

export const formatDate = (date) => {
	const d = new Date(date);
	if (Number.isNaN(d)) return "N/A";
	let month = `${d.getMonth() + 1}`;
	let day = `${d.getDate()}`;
	const year = d.getFullYear();

	if (month.length < 2) month = `0${month}`;
	if (day.length < 2) day = `0${day}`;

	return [day, month, year].join("-");
};

export const formatNumber = (num, point = 2) => {
	const formats = String(num);
	if (Number.isNaN(num)) return 0;
	const toFixed = Number.parseFloat(num).toFixed(point);
	const second = String(toFixed).split(".")[1];
	const first = formats.split(".")[0];
	const third = new Intl.NumberFormat().format(Number(first));
	return `${third}.${second}`;
};

export const convertDateToIso = (dateStr) => {
	const date = new Date(dateStr);
	return date.toISOString();
};

export const isEmpty = (obj) => {
	if (obj && Object.entries(obj).length === 0) return true;
	return false;
};

export const isObjectEmpty = (obj) => {
	return Object.keys(obj).length === 0;
};

export const sum = (obj, prop) => {
	return obj.reduce((item, acc) => item + Number(acc[prop]), 0);
};

export const getDateXDaysAgo = (numOfDays, date = new Date()) => {
	const daysAgo = new Date(date.getTime());

	daysAgo.setDate(date.getDate() - numOfDays);

	return daysAgo;
};

export const getDateXDaysFromNow = (numOfDays, date = new Date()) => {
	const daysAgo = new Date(date.getTime());

	daysAgo.setDate(date.getDate() + numOfDays);

	return daysAgo;
};
export const addMinutes = (minutes, date = new Date()) => {
	return new Date(date.getTime() + minutes * 60000);
};

export const addSeconds = (seconds, date = new Date()) => {
	return new Date(date.getTime() + seconds * 1000);
};

export const numberFormatter = (number, float = 2) => {
	if (number) {
		if (Number.isInteger(Number(number))) {
			return Number(number).toLocaleString("en-US");
		}

		if (!Number.isInteger(Number(number))) {
			return Number(number).toLocaleString("en-US", {
				maximumFractionDigits: float,
				minimumFractionDigits: float,
			});
		}
		return number;
	}
	return "0";
};

export const downloadExcel = (dataToExport, title, filename) => {
	const newData = dataToExport.map((row) => {
		const element = row;
		delete element.tableData;
		return element;
	});
	const workSheet = XLSX.utils.json_to_sheet(newData);
	const workBook = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(workBook, workSheet, title);
	//  Buffer
	// eslint-disable-next-line
	const buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
	//  Binary string
	XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
	//  Download
	XLSX.writeFile(workBook, `${filename}.xlsx`);
};

export const discountCalculator = (originalPrice, discountPrice) => {
	const discount =
		(Number(originalPrice) - Number(discountPrice)) / Number(originalPrice);
	return discount * 100;
};

export const dateFormatter = (str) => {
	if (str) {
		try {
			const dateReverse = str.split(" ")[0];
			const dateArray = dateReverse.split("-");
			return format(
				new Date(dateArray[0], Number(dateArray[1]) - 1, dateArray[2]),
				"do MMM yyyy"
			);
		} catch (error) {
			return "";
		}
	}
	return "";
};

export const newDateFormatter = (str, dateFormat = "do MMM yyyy") => {
	if (str) {
		try {
			return format(new Date(str), dateFormat);
		} catch (error) {
			return "";
		}
	}
	return "";
};

export const dateTimeFormatter = (dateStr) => {
	if (dateStr) {
		const dateReverse = dateStr.split(" ")[0];
		const dateArray = dateReverse.split("-");
		const timeArray = dateStr.split(" ")[1].split(":");
		return format(
			new Date(
				dateArray[0],
				Number(dateArray[1]) - 1,
				dateArray[2],
				timeArray[0],
				timeArray[1],
				timeArray[2]
			),
			"do MMM yyyy  h:mm a"
		);
	}
	return dateStr;
};

export function encrypt(payload) {
	const stringifiedPayload = JSON.stringify(payload);

	const password = "ABCDEF";
	const secretKey = "APEX";

	const hash = CryptoJS.PBKDF2(
		password,
		CryptoJS.enc.Utf16LE.parse(secretKey),
		{
			keySize: 256 / 32,
			iterations: 1000,
			hasher: CryptoJS.algo.SHA1,
		}
	);

	const keyWords = hash.words.slice(0, 6);
	const ivWords = hash.words.slice(6, 8);

	const key = CryptoJS.lib.WordArray.create(keyWords);
	const iv = CryptoJS.lib.WordArray.create(ivWords);

	const encrypted = CryptoJS.TripleDES.encrypt(
		CryptoJS.enc.Utf16LE.parse(stringifiedPayload),
		key,
		{
			iv,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7,
		}
	);

	return encrypted.toString();
}

export function decrypt(encryptedPayload) {
	const password = "ABCDEF";
	const secretKey = "APEX";

	const hash = CryptoJS.PBKDF2(
		password,
		CryptoJS.enc.Utf16LE.parse(secretKey),
		{
			keySize: 256 / 32,
			iterations: 1000,
			hasher: CryptoJS.algo.SHA1,
		}
	);

	const keyWords = hash.words.slice(0, 6);
	const ivWords = hash.words.slice(6, 8);

	const key = CryptoJS.lib.WordArray.create(keyWords);
	const iv = CryptoJS.lib.WordArray.create(ivWords);

	const decrypted = CryptoJS.TripleDES.decrypt(
		{
			ciphertext: CryptoJS.enc.Base64.parse(encryptedPayload),
		},
		key,
		{
			iv,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7,
		}
	);

	const decryptedStr = decrypted.toString(CryptoJS.enc.Utf16LE);

	return JSON.parse(decryptedStr);
}

// export function encryption(payload) {
// 	const password = "ABCDEF";
// 	const secretKey = "APEX";

// 	const key = CryptoJS.PBKDF2(password, CryptoJS.enc.Utf16LE.parse(secretKey), {
// 		keySize: 256 / 8,
// 		iterations: 1000,
// 		hasher: CryptoJS.algo.SHA1,
// 	}).toString(CryptoJS.enc.Hex);
// 	// .substring(0, 48);

// 	// const iv = CryptoJS.enc.Hex.parse(key.substring(200, 256));
// 	// const key1 = CryptoJS.enc.Hex.parse(key.substring(0, 200));
// 	const iv = key.substring(200, 256);
// 	const key1 = key.substring(0, 200);

// 	const key = CryptoJS.lib.WordArray.create(keyWords);
// 	const iv = CryptoJS.lib.WordArray.create(ivWords);

// 	const encryptionOptions = {
// 		iv: iv,
// 		mode: CryptoJS.mode.CBC,
// 		padding: CryptoJS.pad.Pkcs7,
// 	};

// 	console.log({
// 		key1,
// 		iv,
// 		key,
// 	});

// 	const encrypted = CryptoJS.AES.encrypt(
// 		CryptoJS.enc.Utf16LE.parse(JSON.stringify(payload)),
// 		key1,
// 		encryptionOptions
// 	);

// 	return { code: 200, data: encrypted.toString() };
// }]

export function encryption(payload) {
	const stringifiedPayload = JSON.stringify(payload);
	const password = encryptionPassword;
	const secretKey = encryptionSecretKey;

	const hash = CryptoJS.PBKDF2(
		password,
		CryptoJS.enc.Utf16LE.parse(secretKey),
		{
			keySize: 32,
			iterations: 1000,
			hasher: CryptoJS.algo.SHA1,
		}
	).toString(CryptoJS.enc.Hex);

	const key = hash.substring(0, 64);
	const iv = hash.substring(0, 42);

	const encryptionOptions = {
		iv: CryptoJS.enc.Hex.parse(iv),
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7,
	};

	const encrypted = CryptoJS.AES.encrypt(
		CryptoJS.enc.Utf16LE.parse(stringifiedPayload),
		CryptoJS.enc.Hex.parse(key),
		encryptionOptions
	);

	return encrypted.toString();
}

export function decryption(encryptedData) {
	const password = encryptionPassword;
	const secretKey = encryptionSecretKey;

	const hash = CryptoJS.PBKDF2(
		password,
		CryptoJS.enc.Utf16LE.parse(secretKey),
		{
			keySize: 256 / 32,
			iterations: 1000,
			hasher: CryptoJS.algo.SHA1,
		}
	).toString(CryptoJS.enc.Hex);

	const key = hash.substring(0, 64);
	const iv = hash.substring(0, 42);

	const encryptionOptions = {
		iv: CryptoJS.enc.Hex.parse(iv),
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7,
	};

	const decrypted = CryptoJS.AES.decrypt(
		encryptedData,
		CryptoJS.enc.Hex.parse(key),
		encryptionOptions
	);
	const stringifiedDecrypted = CryptoJS.enc.Utf16LE.stringify(decrypted);
	// return JSON.parse(CryptoJS.enc.Utf16LE.stringify(decrypted));
	return JSON.parse(stringifiedDecrypted.trim(""));
}

export const hashString = (dataString) => {
	// Hash the OTP using SHA-256
	const hashedString = CryptoJS.MD5(dataString).toString();
	return hashedString;
};

export const formatNumberToDecimalPlace = (number, decimalPlaces = 2) => {
	// Check if the provided number is not a valid number
	if (Number.isNaN(number)) {
		return "0";
	}

	// Round the number to the specified decimal places
	const roundedNumber = Number(number).toFixed(decimalPlaces);

	// Convert the rounded number to a string
	return Number(roundedNumber).toLocaleString("en-US", {
		maximumFractionDigits: decimalPlaces,
		minimumFractionDigits: decimalPlaces,
	});
};

export function filterArrayBySearchTerm(data, searchTerm) {
	const lowercasedFilter = searchTerm.toLowerCase();

	return data.filter((currentValue) => {
		// Check if any value in user or shareholder contains the search term
		const matchesSearchTerm = Object.values(currentValue).some((val) =>
			String(val).toLowerCase().includes(lowercasedFilter)
		);

		return matchesSearchTerm;
	});
}
